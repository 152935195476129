export const home={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'Industar kft',
    heroP:'Fém megmunkálás',
    imgStart:false,
    to:'about',
    video:require('../../video/15 - Hosszú eszterga.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
  
  };
  export const services={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'Szolgáltatások',
    heroP:'',
    imgStart:false,
    to:'services',
    video:require('../../video/10 - CNC.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  }; 
   export const csohajlitas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'csohajlitas',
    video:require('../../video/1 - Csőhajíitás.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const csodarabolas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'csohajlitas',
    video:require('../../video/2 - Csődarabolás_1.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const lemezmegmunkalas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'lemezmegmunkalas',
    video:require('../../video/4 - Élhajlítás és lemez megmunkálás.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const elektrosztatikusporszoras={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'elektrosztatikusporszoras',
    video:require('../../video/5 - Elektrosztatikus porszórás.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const plazmavagas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'plazmavagas',
    video:require('../../video/9 - Plazmavágás.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const szemcseszoras={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'szemcseszoras',
    video:require('../../video/17 - Mosó.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const hegesztes={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'hegesztes',
    video:require('../../video/14 - Hegesztőrobot.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const kemiaitisztitas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'kemiaitisztitas',
    video:require('../../video/12 - Hátsó kis mosók.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const lezervagas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'lezervagas',
    video:'',
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const furesztarcsaelezes={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'furesztarcsaelezes',
    video:require('../../video/6 - Fűrésztárcsa élezés.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const forgacsolas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'forgacsolas',
    video:require('../../video/7 - Forgácsolás.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };
  export const koordinatalyukasztas={
    id:'about',
    lightBg:false,
    lightText:true,
    lightTextDesc:false,
    heroH1:'',
    heroP:'',
    imgStart:false,
    to:'koordinatalyukasztas',
    video:require('../../video/3 - Koordináta lyukasztás.mp4'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:true,
   
  };